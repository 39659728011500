<template>
  <v-container id="particles-js">
    <snackbarNotification ref="snackbarRef" />
    <div class="d-flex justify-end align-center">
      <p class="text-body-1-base ml-6">Já tem uma conta?</p>
      <v-btn
        variant="plain"
        class="text-body-2-base"
        color="#122167"
        @click="goLogin"
      >
        Fazer Login
      </v-btn>
    </div>
    <div class="d-flex justify-center align-center">
      <v-card
        width="380"
        height="580"
        elevation="20"
        class="pa-6 py-4 box-card"
      >
        <div class="mt-6 text-center">
          <p class="text-h5-base font-weight-bold">Registro</p>
          <p class="text-body-1-base" style="color: #9e9e9e">
            Insira seus dados para criar uma conta
          </p>
        </div>
        <form class="mt-12" action="" @submit="checkRegister">
          <v-text-field
            label="Nome"
            v-model="name"
            clearable
            variant="solo"
            required
          ></v-text-field>
          <v-text-field
            label="E-mail"
            v-model="email"
            :rules="[emailRule]"
            clearable
            variant="solo"
            required
            @change="checkEmail"
          ></v-text-field>
          <v-text-field
            label="Senha"
            v-model="password"
            type="password"
            :rules="[passwordRule]"
            variant="solo"
            required
            clearable
            @input="checkPassword"
          ></v-text-field>
          <v-text-field
            label="Confirmar senha"
            v-model="confirmPassword"
            type="password"
            :rules="[confirmPasswordRule]"
            variant="solo"
            required
            clearable
            @input="checkConfirmPassword"
          ></v-text-field>
          <div class="d-flex align-center justify-start mt-2">
            <v-btn
              :disabled="!isValid"
              type="submit"
              color="#122167"
              size="large"
              variant="flat"
              ><p class="font-weight-bold text-white text-body-2-base">
                Registrar
              </p></v-btn
            >
          </div>
        </form>
      </v-card>
    </div>
  </v-container>
</template>
<script>
  import snackbarNotification from "./snackbarNotification.vue"
  import Authantication from "@/Authantication"

  export default {
    name: "registerForm",
    components: {
      snackbarNotification,
    },
    data() {
      return {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        emailValid: true,
        passwordValid: false,
        passwordTouched: false,
        confirmPasswordValid: false,
        confirmPasswordTouched: false,
        emailRule: [(v) => /.+@.+\..+/.test(v) || "E-mail inválido"],
        passwordRule: [
          (v) =>
            (v && v.length >= 6 && /\d/.test(v)) ||
            "A senha deve ter pelo menos 6 caracteres e conter pelo menos 1 número",
        ],
      }
    },
    mounted() {
      const particlesJS = window.particlesJS
      particlesJS("particles-js", {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#122167",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: "img/github.svg",
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#c8581b",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      })
    },
    methods: {
      checkEmail() {
        // eslint-disable-next-line no-extra-semi
        ;/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(this.email)
          ? (this.emailValid = true)
          : (this.emailValid = false)
      },
      checkUpperCase(str) {
        return /[A-Z]/.test(str) && /[0-9]/.test(str)
      },
      checkPassword() {
        const userName = this.email.split("@")[0]
        const passwordElement = document.getElementById("Password")
        const passwordErrorElement = document.getElementById("passwordError")

        if (
          this.password.length >= 6 &&
          this.checkUpperCase(this.password) &&
          this.password !== userName
        ) {
          this.passwordValid = true
          if (passwordElement) {
            passwordElement.classList.remove("notValid")
            passwordElement.classList.add("valid")
          }
          if (passwordErrorElement) {
            passwordErrorElement.classList.add("disable")
          }
        } else if (this.passwordTouched) {
          this.passwordValid = false
          if (passwordElement) {
            passwordElement.classList.remove("valid")
            passwordElement.classList.add("notValid")
          }
          if (passwordErrorElement) {
            passwordErrorElement.classList.remove("disable")
          }
        }

        this.checkConfirmPassword()
      },

      checkConfirmPassword() {
        if (this.confirmPassword === this.password) {
          this.confirmPasswordValid = true
        } else {
          this.confirmPasswordValid = false
        }
      },
      checkRegister(e) {
        e.preventDefault()

        if (this.isValid && this.password === this.confirmPassword) {
          const userData = {
            email: this.email,
            password: this.password,
            re_password: this.password,
          }

          Authantication.register(
            userData.email,
            userData.password,
            userData.re_password
          )
            .then((response) => {
              console.log(response.data) // A resposta da API
              this.$refs.snackbarRef.showSuccess(
                "Registro realizado com sucesso!"
              )
              localStorage.setItem("userEmail", userData.email)
              this.$router.push("/")
            })
            .catch((error) => {
              console.error("Erro no registro:", error)
              this.$refs.snackbarRef.showError(
                "Erro ao realizar registro, tente novamente!"
              )
              this.clearRegistro()
            })
        } else {
          // Exibe uma mensagem de erro se os campos não estiverem validados
          console.error(
            "Erro no registro: Campos inválidos ou senhas não coincidem"
          )
        }
      },
      clearRegistro() {
        this.name = ""
        this.email = ""
        this.password = ""
        this.confirmPassword = ""
        this.passwordValid = false
      },
      goLogin() {
        this.$router.push("/")
      },
    },

    computed: {
      isValid() {
        return (
          this.emailValid &&
          this.password.length >= 6 &&
          this.confirmPasswordValid &&
          this.email
        )
      },

      confirmPasswordRule() {
        return this.confirmPasswordValid || "As senhas não coincidem"
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/style/main.scss";
  #particles-js {
    position: relative;
    width: 100%;
    height: 100vh;
  }
  .box-card {
    position: absolute;
    margin-top: 75%;
  }
</style>
